@import '_variables.scss';

:root {
	--primary-10: #{rgba($primary, 0.1)};
	--secondary-10: #{rgba($secondary, 0.1)};
	--success-3-10: #{rgba($success, 0.1)};
	--warning-4-10: #{rgba($warning, 0.1)};
	--danger-10: #{rgba($danger, 0.1)};
	--info-10: #{rgba($info, 0.1)};
	--primary-color: #{$navy};
	--separator-color: #{$gray-200};
}

body {
	margin: 0;
	padding: 0;
}
.App {
	background: #ededed;
	min-height: 100vh;
	position: relative;
	padding-top: 90px;
	padding-bottom: 110px;
}
nav {
	&.navbar {
		background-color: darkgrey !important;
		height: 80px;
		.navbar-brand {
			img {
				height: auto;
				width: 200px;
			}
		}
	}
}
main.container {
	// margin-top: 80px;
}
footer {
	background-color: #d6d6d6;
	padding: 1px;
	font-weight: bold;
	text-align: center;
	position: absolute;
	bottom: 0px;
	height: 100px;
	p:last-child {
		margin-bottom: 0;
	}
}
p {
	font-family: $font-family-sans-serif;
}
.loading {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 2px solid rgba($primary, 0.2);
	border-radius: 50%;
	border-top-color: $primary;
	animation: spin 1s ease-in-out infinite;
	-webkit-animation: spin 1s ease-in-out infinite;
	left: calc(50% - 15px);
	top: calc(50% - 15px);
	position: fixed;
	z-index: 1;
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
.dashboard-filled-line-chart {
	height: 340px;
}
.border-danger .form-error {
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: $danger;
}
.modal-90w {
	width: 90%;
	max-width: none !important;
}

.ReactTable .rt-thead.-header {
	box-shadow: initial;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
	padding-top: 10px;
	padding-bottom: 10px;
	box-shadow: initial;
	border: initial;
	text-align: left;
	font-weight: 700;
}

.ReactTable .rt-tbody .rt-tr-group {
	border-bottom: 1px solid $gray-200;
}

.ReactTable .list-item-heading {
	margin-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
	border-right: initial;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
	box-shadow: inset 0 -3px 0 0 $primary;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
	box-shadow: inset 0 3px 0 0 $primary;
}

/* 38.Tables */
.table.datatable {
	th,
	td,
	.th,
	.td {
		border: 1px solid $gray-300;
		color: $dark;
	}
	.tr {
		border-right: 1px solid $gray-300;
	}
}

.table.datatable .thead-light {
	th,
	.th {
		background-color: $gray-100 !important;
	}
}

.table-striped.datatable tbody,
.table-striped.datatable .tbody {
	tr,
	.tr {
		&:nth-of-type(odd) {
			background-color: $gray-100 !important;
		}
	}
}

.react-select {
	outline: initial !important;
	box-shadow: none !important;
}

.react-select__value-container {
	outline: initial !important;
	box-shadow: none !important;
	padding: 0.3rem 0.75rem 0.3rem 0.75rem !important;
	// background: $input-bg !important;
}

.react-select .react-select__dropdown-indicator {
	color: $secondary;
}

.react-select .react-select__menu-list {
	padding-bottom: 0;
	padding-top: 0;
}

.react-select .react-select__single-value,
.react-select .react-select__multi-value__label {
	color: #495057;
}

.react-select .react-select__dropdown-indicator,
.react-select .react-select__control--is-focused .react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select .react-select__control--is-focused .react-select__clear-indicator {
	outline: initial;
	box-shadow: initial;

	&:active,
	&:focus,
	&:hover {
		color: $primary !important;
	}
}
.react-select.is-invalid .react-select__control {
	// border-radius: 0.25rem !important;
	border: 1px solid $danger !important;
}

.react-select__control {
	// border-radius: 0.25rem !important;
	border: 1px solid $input-border-color !important;
	background: $white !important;
	outline: initial !important;
	box-shadow: none !important;
}

.react-select__indicator-separator {
	display: none;
}

.react-select__dropdown-indicator svg {
	width: 15px;
	height: 15px;
}

.react-select__option {
	background: $input-group-addon-bg !important;
	color: #495057 !important;

	&:hover,
	&:active {
		background: $secondary !important;
		color: $primary !important;
	}
}

.react-select__option--is-selected {
	background: #{rgba($primary, 0.1)} !important;
	color: $primary !important;
}

.react-select__option--is-focused {
	color: $primary !important;
	background: $secondary !important;
}

.react-select__control--is-focused {
	border-color: $blue !important;
}

.react-select__multi-value {
	background: transparent !important;
	border: 1px solid $input-border-color !important;
}

.react-select__multi-value__remove:hover,
.react-select__multi-value__remove:active {
	background: transparent !important;
	color: $primary !important;
}

.react-select .react-select__menu {
	border-radius: 0.25rem !important;
	z-index: 1000 !important;
	box-shadow: initial !important;
	border: 1px solid $blue !important;
	border-top: initial !important;
	margin-top: -8px !important;
	background-color: $white !important;
	border-width: 1px !important;
}

.react-select__single-value {
	bottom: 0 !important;
	top: 50% !important;
	padding-top: 2px !important;
}

.react-select__input {
	color: $primary;
}
.react-select__value-container .css-rsyb7x {
	margin: 0 !important;
	padding: 0 !important;
}
.react-select__single-value {
	top: unset !important;
	bottom: -3px !important;
	margin-left: 0 !important;
}
.carousel-inner {
	overflow: visible;
}
